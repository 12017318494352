import { useState, FormEvent, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { Accordion, Card } from 'react-bootstrap';
import { Product } from '../pages/Products';
import LoadingModal from '../modals/LoadingModal';
import OrderFormReCaptchaModal from './OrderFormReCaptchaModal';
import RadioButtonShipping from '../components/RadioButtonShipping';
import RadioButtonPayment from '../components/RadioButtonPayment';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

interface OrderModalProps {
    show: boolean,
    onHide(): void,
    product: Product,
}

export default function OrderModal({ show, onHide, product }: OrderModalProps) {
    const [count, setCount] = useState(1);
    const [price, setPrice] = useState(product.price * count);
    const [added, setAdded] = useState(false);
    const [removed, setRemoved] = useState(false);
    const [shipping, setShipping] = useState("");
    const [payment, setPayment] = useState("");
    const [showReCaptchaModal, setShowReCaptchaModal] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [verified, setVerified] = useState(false);

    const form = useRef(document.createElement("form"));

    useEffect(() => {
        if (added) {
            setPrice(price + product.price);
        }
        else if (removed) {
            setPrice(price - product.price);
        }
    }, [count]);

    useEffect(() => {
        setPrice(product.price * count);
    }, [product.price])

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (showReCaptchaModal) {
            setShowReCaptchaModal(false);
            setShowLoadingModal(true);

            emailjs.sendForm('service_zskuhzv', 'template_qygpjq3', form.current, 'ZXc6ielx0mDYZCcJe')
                .then((result) => {
                    setShowLoadingModal(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'Bestellung erfolgreich',
                        text: 'Ihre Bestellung ist erfolgreich bei uns eingegangen. Sie werden in Kürze eine Bestätigung Ihrer Bestellung per E-Mail erhalten.',
                        confirmButtonColor: '#088a29',
                    });
                    const form = document.getElementById("order-form") as HTMLFormElement;
                    form.reset();
                    onHide();
                }, (error) => {
                    setShowLoadingModal(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: 'Ihre Bestellung konnte nicht an uns übermittelt werden. Versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
                        confirmButtonColor: '#088a29',
                    });
                });
        } else {
            setShowReCaptchaModal(true);
        }
    }

    const onHideReCaptcha = () => {
        setShowReCaptchaModal(false);
        setVerified(false);
    }

    const increment = () => {
        setCount(count + 1);
        setAdded(true);
        setRemoved(false);
    }

    const decrease = () => {
        setCount(count - 1);
        setRemoved(true);
        setAdded(false);
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            size="lg"
            centered
            id="order-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Bestellung
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="image-text-inline">
                    <img src={product.image} alt={product.name} />
                    <div className="text">
                        <h2>{product.name}</h2>
                        <div className="counter">
                            <div className="button-button">
                                <button className="button" type="button" onClick={increment}><FaPlus /></button>
                            </div>

                            <input type="number" value={count} readOnly />
                            {
                                count > 1 &&
                                <div className="button-button">
                                    <button className="button" type="button" onClick={decrease}><FaMinus /></button>
                                </div>
                            }
                        </div>
                        <p>CHF {price}</p>
                    </div>
                </div>
                <h3>Lieferadresse &amp; Kontaktdaten</h3>
                <form ref={form} id="order-form" onSubmit={handleSubmit}>
                    <input type="text" name="firstname" placeholder="Vorname *" required />
                    <input type="text" name="lastname" placeholder="Nachname *" required />
                    <input type="text" name="street" placeholder="Strasse *" required />
                    <input type="text" name="streetnr" placeholder="Hausnummer *" required />
                    <input type="text" name="zip" placeholder="PLZ *" required />
                    <input type="text" name="place" placeholder="Ort *" required />
                    <input type="email" name="email" placeholder="E-Mail-Adresse *" required />
                    <input type="tel" name="phone" placeholder="Telefonnummer" />
                    <input type="hidden" name="payment" value={payment} />
                    <input type="hidden" name="shipping" value={shipping} />
                    <input type="hidden" name="count" value={count} />
                    <input type="hidden" name="price" value={price} />
                    <input type="hidden" name="product" value={product.name} />
                </form>
                {
                    showReCaptchaModal &&
                    <OrderFormReCaptchaModal show={showReCaptchaModal} onHide={() => onHideReCaptcha()} verified={verified} setVerified={() => setVerified(true)} />
                }
                {
                    showLoadingModal &&
                    <LoadingModal show={showLoadingModal} onHide={() => setShowLoadingModal(false)} />
                }
                <h3>Zahlungsart wählen</h3>
                <Accordion>
                    <Card>
                        <Card.Header>
                            <RadioButtonPayment eventKey="0" id="vorauskasse" neighbours={["kreditkarte", "twint"]} formId="order-form" setPayment={() => setPayment("Vorauskasse")}>Vorauskasse</RadioButtonPayment>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p>Bei Bestellungen mit Vorauskasse erhalten Sie eine E-Mail mit der Bitte, den entsprechenden Betrag auf unser nachfolgendes Konto zu überweisen:</p>
                                <address>
                                    Credit Suisse<br />
                                    Shqipe Zenuni<br />
                                    CH33 0483 5032 4907 2000 0
                                </address>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <RadioButtonPayment eventKey="1" id="kreditkarte" neighbours={["vorauskasse", "twint"]} formId="order-form" setPayment={() => setPayment("Kreditkarte")}>Kreditkarte</RadioButtonPayment>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>Zurzeit noch nicht verfügbar.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <RadioButtonPayment eventKey="2" id="twint" neighbours={["vorauskasse", "kreditkarte"]} formId="order-form" setPayment={() => setPayment("TWINT")}>TWINT</RadioButtonPayment>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <p>Bei Bestellungen mit TWINT erhalten Sie eine E-Mail mit der Bitte, den entsprechenden Betrag an die nachfolgende Handynummer und Namen zu senden:</p>
                                <p>+41 78 632 07 06<br />Shqipe Zenuni</p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <h3>Lieferart wählen</h3>
                <Accordion>
                    <Card>
                        <Card.Header>
                            <RadioButtonShipping eventKey="0" id="a-post" neighbours={[]} formId="order-form" setInitPrice={() => setPrice(product.price * count)} setPrice={() => setPrice((product.price * count) + 9)} setShipping={() => setShipping("A-Post")}>A-Post (+ CHF 9 Versandkosten)</RadioButtonShipping>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p>A-Post wird am nächsten Werktag zugestellt. Am Sonntag und an Feiertagen werden keine Briefe zugestellt.</p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <input form="order-form" type="submit" value={`CHF ${price} - Bestellen`} />
            </Modal.Footer>
        </Modal >
    );
}