import React from 'react';
import craniosacralTherapyImage from './../assets/images/services/caniosacral_therapy.jpg';
import dornTherapyImage from '../assets/images/services/dorn_therapy.jpg';
import tapingImage from '../assets/images/services/taping.jpg';
import treatmentRoomImage from '../assets/images/services/treatment_room.jpg';

export default function Services() {
    return (
        <div id="services">
            <h1>Craniosacral-Therapie</h1>
            <div className="service craniosacral-theraphy image-text-inline">
                <img src={craniosacralTherapyImage} alt="Craniosacral-Therapie" />
                <div className="text">
                    <p>In der Behandlung werde ich ganz sanfte, feine manuelle Impulse auslösen, welche die Eigenregulierung Ihres Körpers einleiten. Die Gesundheit im Körpersystem wird sanft unterstützt. Störungen und Blockaden werden aufgelöst und die Ressourcen im gesamten Körpersystem gestärkt. Damit können positive Veränderungen in Richtung Gesundheit stattfinden.</p>
                </div>
            </div>
            <h2>Dorn Therapie</h2>
            <div className="service dorn-therapy image-text-inline">
                <img src={dornTherapyImage} alt="Dorn-Therapie" />
                <div className="text">
                    <h3 className="center">Die Wirbelsäule, unser zentrales Stützorgan</h3>
                    <p>Schmerzen, Funktionsstörungen und Veränderungen des Gewebes- nicht nur am Rücken, sondern auch in jeder andere Körperregion- sind häufig verursacht durch eine Störung der aus dem Rückenmark herausführenden Nerven; infolge einer Verschiebung, Fehlstellung oder Blockierungen einzelner oder mehreren Wirbel.</p>
                    <p>In solchen Fällen liegt der Ansatzpunkt für eine wirkungsvolle Behandlung: Fühlt die Fehlstellung des Wirbels und korrigiert sein! Das Wirbelrichten nach Dieter Dorn ist im Prinzip eine sehr einfache und ungefährliche Methode. Auch der Laie kann sich selbst und seinen Mitmenschen in vielen Fällen gut helfen.</p>
                </div>
            </div>
            <h2>Taping nach Craniosacral-Therapie</h2>
            <div className="service taping image-text-inline">
                <img src={tapingImage} alt="Taping nach Craniosacral Therapie" />
                <div className="text">
                    <p>Mit dem Tape können wir über die Haut auf die Faszien wirken, und somit auch auf das Craniosacrale System. Das Taping kann nicht neu erfunden werden, doch die Betrachtung und die Ziele, was das Tape bewirken soll, ist individuell und macht den entscheidenen Unterschied.</p>
                    <div className="ul-center">
                        <h3>Spezifisches Taping bewirkt</h3>
                        <ul>
                            <li>Regulierungen der Muskel- und Faszienspannung</li>
                            <li>Beseitigung muskulärer Dysbalancen</li>
                            <li>Korrektur des Faszienverlaufes</li>
                            <li>Erhöhung der Mikrozirkulationen von Blut und Lymphen</li>
                            <li>Behandlung von Triggerpunkten und Strain/Counterstrain</li>
                            <li>Funktionelle Korrektur von Gelenken</li>
                            <li>Mechanische Korrektur von Gelenken</li>
                            <li>Verbesserte Propriozeption</li>
                            <li>Verbesserung und Erleichterung von spezifischen Bewegungsmustern</li>
                            <li>Positive Beeinflussung des neurologischen System</li>
                            <li>Behandlung von Narben</li>
                            <li>Über die Faszien auf das durale System</li>
                        </ul>
                    </div>
                </div>
            </div>
            <h2>Dorn &amp; Craniosacral Therapie hilft bei</h2>
            <div className="service aid">
                <div className="side-margin boxes">
                    <ul>
                        <li>Beckenschiefstand</li>
                        <li>Skoliose</li>
                        <li>Ischias</li>
                        <li>Hexenschuss</li>
                        <li>Ileosacral-Gelenks-Probleme</li>
                    </ul>
                    <ul>
                        <li>Hüft-, Knie- und Fuss-Probleme</li>
                        <li>LWS-Probleme</li>
                        <li>Schulterschmerzen</li>
                        <li>Tennisarm</li>
                        <li>HWS-Probleme</li>
                    </ul>
                    <ul>
                        <li>Kopfschmerzen</li>
                        <li>Schwindel</li>
                        <li>Tinnitus</li>
                        <li>Ohrrauschen</li>
                        <li>Hörsturz</li>
                    </ul>
                    <ul>
                        <li>Hör- und Sehprobleme</li>
                        <li>Bandscheibenvorfall</li>
                        <li>Empfindungsstörungen an Händen oder Füssen</li>
                        <li>Rücken-Probleme nach Operationen</li>
                        <li>Schuheinlagen</li>
                    </ul>
                </div>
            </div>
            <h2>Preise</h2>
            <div className="price">
                <p>CHF 135 á 60 Minuten</p>
                <img src={treatmentRoomImage} alt="Behandlungsraum" />
            </div>
        </div>
    );
}