import React from 'react';
import { FaPhoneAlt } from 'react-icons/fa';

export default function Header() {
    return (
        <section id="header">
            <FaPhoneAlt />
            <div>
                <p>Vereinbaren Sie einen Termin</p>
                <a className="phone" href="tel:+41786320706">+41 78 632 07 06</a>
                <a href="mailto:info@dorn-craniosacral.ch">info@dorn-craniosacral.ch</a>
            </div>
        </section>
    );
}