import React from 'react';
import { Link } from 'react-router-dom';
import vacationImage from '../assets/images/vacation.jpg';
import homeImage from '../assets/images/home.jpg';
import scranioImage from '../assets/images/products/scranio.jpg';

export default function Home() {
    return (
        <div id="home">
            <h1>Herzlich Willkommen in meiner Praxis</h1>
            {/* <div className="image-text-inline">
                <img className="box-shadow " src={vacationImage} alt="Urlaub" />
                <div className="text">
                    <p>Urlaubsbedingt ist die Praxis von Freitag, 30.09.2022 bis Mittwoch, 12.10.2022 geschlossen. Nachrichten werden nicht weitergeleitet oder bearbeitet. Ich freue mich sehr Euch nach meinem Urlaub wieder in meiner Praxis willkommen zu heissen.</p>
                </div>
            </div> */}
            <div className="image-text-inline">
                <img className="box-shadow " src={homeImage} alt="Kraft der Achtsamkeit" />
                <div className="text">
                    <p>Wenn die Kraft der Achtsamkeit und des mitfühlenden Zuhörens in dir ist, kann deine Gegenwart eine heilende und beruhigende Wirkung auf andere ausüben. Du brauchst nur da zu sein und den Menschen, die dir vertraut sind, zuzuhören.</p>
                </div>
            </div>
            <div className="image-text-inline">
                <img src={scranioImage} alt="sCranio" />
                <div className="text">
                    <p>sCranio hilft effektiv Probleme im atem- oder Schluckprozess dauerhaft zu bekämpfen. Durch eine Behandlung mit dem sCranio werden auf natürliche Weise nicht nur ein, sondern bis zu 148 Muskeln gleichzeitig stimuliert. sCranio ist ein hauseigenes schweizer Produkt von Dorn &amp; CranioSacral.</p>
                    <div className="button">
                        <Link to={{ pathname: "/products", hash: "#scranio" }}>Zum Produkt</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}