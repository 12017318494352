import React from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import logo from '../assets/images/logo.png';

export default function Navbar() {

    const openSidenav = () => {
        const sidenav = document.getElementById("sidenav");
        if (sidenav !== null) {
            sidenav.style.width = "275px";
        }
    }

    return (
        <nav id="navbar">
            <Link to="/"><img src={logo} alt="Dorn &amp; CranioSacral Therapie" /></Link>
            <div className="links">
                <Link to="/">Home</Link>
                <Link to="/about">Über mich</Link>
                <Link to="/services">Craniosacral-Therapie</Link>
                <Link to="/products">Produkte</Link>
                <Link to="/contact">Kontakt</Link>
                <a href="https://www.studiobookr.com/dorn-craniosacral-therapie-61135" rel="noreferrer" target="_blank">Termin online buchen</a>
            </div>
            <button type="button" className="hamburger" onClick={openSidenav}><FaBars /></button>
        </nav>
    );
}