import React from 'react';
import { useAccordionButton } from 'react-bootstrap';

interface RadioButtonPaymentProps {
    children: string,
    eventKey: string,
    id: string,
    neighbours: Array<string>,
    formId: string,
    setPayment(): void,
}

export default function RadioButtonPayment({ children, eventKey, id, neighbours, formId, setPayment }: RadioButtonPaymentProps) {
    
    const onClickRadioButton = useAccordionButton(eventKey, () => {
        const radioButton = document.getElementById(id) as HTMLInputElement;
        radioButton.checked = true;
        radioButton.required = true;
        setPayment();
        neighbours.forEach(element => {
            const radioButton = document.getElementById(element) as HTMLInputElement;
            radioButton.checked = false;
            radioButton.required = false;
        });
    });

    return (
        <div className="radio-button" onClick={onClickRadioButton}>
            <input id={id} type="radio" name={children} form={formId} value={id} required />
            <label htmlFor={children}>{children}</label>
        </div>
    );
}