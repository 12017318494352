import React from 'react';
import { FaInstagram, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ascaImage from '../assets/images/health_insurances/asca.png';
import emrImage from '../assets/images/health_insurances/emr.png';
import cranioSuisseImage from '../assets/images/health_insurances/cranio_suisse.png';

export default function Footer() {
    return (
        <footer id="footer">
            <hr />
            <h2>Shqipe Zenuni</h2>
            <h3>Dipl. Craniosacral-Therapeutin</h3>
            <div className="icon-text-inline instagram">
                <FaInstagram />
                <a href="https://www.instagram.com/dorn.craniosacral.therapie/?hl=de" target="_blank" rel="noreferrer">Instagram</a>
            </div>
            <div className="contact">
                <div className="icon-text-inline">
                    <FaMapMarkerAlt />
                    <a href="https://www.google.ch/maps/place/Dorn+%26+Craniosacral+Therapie/@47.3548101,7.9032452,17z/data=!3m1!4b1!4m5!3m4!1s0x47903171548d55ab:0xd178c7cd7c59509a!8m2!3d47.3548101!4d7.9054339?shorturl=1" target="_blank" rel="noreferrer">
                        <address>
                            Amthausquai 33, 4600 Olten
                        </address>
                    </a>
                </div>
                <div className="icon-text-inline">
                    <FaPhoneAlt />
                    <a href="tel:+41786320706">+41 78 632 07 06</a>
                </div>
                <div className="icon-text-inline">
                    <FaEnvelope />
                    <a href="mailto:info@dorn-craniosacral.ch">info@dorn-craniosacral.ch</a>
                </div>
            </div>
            <div className="button">
                <Link to="/terms-and-conditions">Datenschutz &amp; AGB</Link>
            </div>
            <div className="insurances side-margin">
                <img src={ascaImage} alt="asca" />
                <img src={emrImage} alt="EMR" />
                <img src={cranioSuisseImage} alt="Cranio Suisse" />
            </div>
            <p>Krankenkasse anerkannt ausser Visana (VVG)</p>
        </footer>
    );
}